<template>
  <div class="profile container flex justify-center py-4 md:py-16">
    <div class="box edit_box col-lg-9 space-y-4">
      <div class="row">
        <div>
          <h3 class="text-center"> {{ $t('profile.fotoPerfil') }}</h3>
          <div class="preview d-flex justify-content-center mt-4">
            <img id="file-preview" :src="url" alt="Profile-Picture" v-if="url" >
          </div>
          <div class="d-flex justify-content-center mt-3 mb-4">
            <label for="profile-picture" class="w-full  sm:w-5/12"> {{ url == null ? $t('profile.enviarImagem') : $t('profile.alterarImagem') }} </label>
            <input 
              type="file" 
              name="profile-picture" 
              id="profile-picture"
              ref="profilePicture"
              accept="image/*"
              @change="showPreview"
              >
          </div>

        </div>

        <div class="col-lg-6 account-info mt-4">
          <h3 class="mb-6">{{ $t("profile.informacoes-pessoais") }}</h3>
          <div class="form-group space-y-3 mb-0">
            <div class="space-y-4">
              <div class="space-y-3">
                <span class="nameInput">{{ $t("profile.primeiro-nome") }}</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.first_name"
                />
                <FieldError :invalidFields="invalidFields" name="first_name" />
              </div>
              <div class="space-y-3">
                <span class="nameInput">{{ $t("profile.ultimo-nome") }}</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.last_name"
                />
                <FieldError :invalidFields="invalidFields" name="last_name" />
              </div>
              <div class="space-y-3">
                <span class="nameInput d-flex justify-content-between"
                  >{{ $t("profile.email") }}</span
                >
                <div class="confirm">
                  <input
                    type="text"
                    class="form-control"
                    style="opacity: 0.75"
                    readonly
                    :value="user.email"
                    placeholder=""
                  />
                  <FieldError :invalidFields="invalidFields" name="email" />
                </div>
              </div>
              <div class="space-y-3">
                <span class="nameInput d-flex justify-content-between"
                  >{{ $t("profile.cpf") }}</span
                >
                <div class="confirm">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.cpf"
                    placeholder=""
                    v-maska="'###.###.###-##'"
                    :style="user.cpf != null ? 'opacity: 0.75' : ''"
                    :readonly="user.cpf != null"
                  />
                  <FieldError :invalidFields="invalidFields" name="cpf" />
                </div>
              </div>
              <div class="space-y-3">
                <span class="nameInput d-flex justify-content-between"
                  >{{ $t("profile.celular") }}</span
                >
                <div class="confirm">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.phone"
                    v-maska="'(##) #####-####'"
                    placeholder=""
                  />
                  <FieldError :invalidFields="invalidFields" name="phone" />
                </div>
              </div>
              <div class="space-y-3">
                <span class="nameInput">{{ $t("profile.sobre") }}</span>
                <textarea
                  style="min-height: 110px"
                  placeholder=""
                  v-model="form.about"
                >
                </textarea>
                <FieldError :invalidFields="invalidFields" name="about" />
              </div>
              <div class="space-y-3">
                <input
                  id="hidden-collection"
                  type="checkbox"
                  v-model="form.hidden_collection"
                  style="visibility: visible; width: auto; height: auto; margin-right: 8px;"
                />
                <span
                  @click="form.hidden_collection = !form.hidden_collection"
                  for="hidden-collection"
                  class="cursor-pointer nameInput"
                  >{{ $t("profile.esconder-colecao") }}</span
                >
              </div>
              <div class="changePassword">
              <a
                class="avatars_name text-primary text-m"
                @click="showModal = true"
                >{{ $t("profile.trocar-senha") }}</a
              >
            </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 social-media mt-4">
          <h3 class="mb-6">{{ $t("profile.redes-sociais") }}</h3>
          <div class="form-group space-y-3">
            <div class="space-y-4">
              <div class="d-flex flex-column space-y-1">
                <span class="nameInput mb-2">{{ $t("profile.facebook") }}</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.facebook"
                  :placeholder="$t('profile.facebook')"
                />
              </div>
              <div class="d-flex flex-column space-y-1">
                <span class="nameInput mb-2">{{ $t("profile.twitter") }}</span>
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('profile.twitter')"
                  v-model="form.twitter"
                />
              </div>
              <div class="d-flex flex-column space-y-1">
                <span class="nameInput mb-2">{{ $t("profile.instagram") }}</span>
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('profile.instagram')"
                  v-model="form.instagram"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hr"></div>
      <div>
        <button class="btn btn-grad" @click="uploadProfilePicture">
          {{ $t("profile.atualizar-perfil") }}
        </button>
      </div>
      <ChangePassword v-show="showModal" @close-modal="showModal = false" />
      <div class="toast" :class="{ active: toastActive, message: message }">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api";
import router from '@/router';
import FieldError from "../components/FieldError";
import ChangePassword from "../components/ChangePassword";

export default {
  components: { FieldError, ChangePassword },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        username: "",
        phone: "",
        about: "",
        cpf: "",
        instagram: "",
        facebook: "",
        twitter: "",
        hidden_collection: false,

      },
      url: null,
      profilePic: null,
      toastActive: false,
      message: "",
      invalidFields: [],
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    user() {
      this.syncUser();
    },
  },
  created() {
    if (!this.user) {
      this.$store.dispatch("fetchUser");
    }

    this.syncUser();
  },
  methods: {
    async update() {
      if (this.formIsValid()) {
        api
          .put("user/update", this.form)
          .then(async (data) => {
            if (data.errors) {
              const fields = Object.keys(data.errors);
              fields.map((field) => {
                this.invalidFields.push(`${field}:${data.errors[field][0]}`);
              });
            } else {
              this.uploadProfilePicture();
              this.message = "Perfil atualizado com sucesso.";
              this.toastActive = true;
            }
          })
          .catch((err) => console.log(err));

        setTimeout(() => {
          this.toastActive = false;
        }, 3000);
      }
    },
    uploadProfilePicture() {
      const { VUE_APP_URL } = process.env;
      let headers = { "Authorization": `Bearer ${window.localStorage.getItem('token')}`, "Accept": "javascript/json" }
      new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('first_name', this.form.first_name)
        formData.append('last_name', this.form.last_name)
        formData.append('phone', this.form.phone)
        formData.append('about', this.form.about)
        formData.append('username', this.form.username)
        formData.append('cpf', this.form.cpf)
        formData.append('instagram', this.form.instagram)
        formData.append('facebook', this.form.facebook)
        formData.append('twitter', this.form.twitter)
        if(this.profilePic) {
          formData.append('profile_pic', this.profilePic[0])
        }

        fetch(VUE_APP_URL + 'api/user/update', {
          method: 'POST',
          headers,
          body: formData,
        }).then(response => {
          return resolve(
            response.json().then(() => {
              this.message = "Perfil atualizado com sucesso.";
              this.toastActive = true;
              //atualizando a página para aparecer as alterações
              this.$store.dispatch('fetchUser')
              router.push({ name: 'UserDetails', params: {id: this.user.username} })    
            }))
        }).catch(error => {
          return reject(console.error(error))
        })
      })
        setTimeout(() => {
          this.toastActive = false;
          
        }, 3000);
    },

    syncUser() {
      if (this.user) {
        this.form.first_name = this.user.first_name;
        this.form.last_name = this.user.last_name;
        this.form.username = this.user.username;
        this.form.phone = this.user.phone;
        this.form.about = this.user.about;
        this.form.cpf = this.user.cpf;
        this.form.instagram = this.user.instagram;
        this.form.facebook = this.user.facebook;
        this.form.twitter = this.user.twitter;
        this.form.hidden_collection = this.user.hidden_collection == 1;
        this.url = this.user.profile_pic;
      }
    },
    formIsValid() {
      let valid = true;
      this.invalidFields = [];

      Object.keys(this.form).map((field) => {
        if (this.form[field] === "") {
          this.invalidFields = [
            ...this.invalidFields,
            `${field}:Este campo não pode ser vazio.`,
          ];
          valid = false;
          this.invalidFields;
        }
      });
      return valid;
    },
    clearError() {
      this.$refs.invalidFields.reset();
    },
    showPreview(e) {
      const file = e.target.files[0]
      this.profilePic = this.$refs.profilePicture.files;
      this.url = URL.createObjectURL(file);
    }
  },
};
</script>

<style scoped>
.edit_box {
  background: #2e2e2e;
  border-color: transparent;
}
.edit_box label,
.edit_box span {
  color: #fff !important;
}
.edit_box h3 {
  font-weight: 600;
  color: white;
  font-size: 20px;
}
.edit_box input {
  background: #191919;
}

.toast {
  position: fixed;
  right: 20px;
  top: 70px;
  background: #01e769;
  padding: 15px;
  transition: all 0.6s;
  transform: translateY(-15px);
  opacity: 0;
  pointer-events: none;
}

.toast.active {
  transform: translateY(0);
  opacity: 1;
}

input[type="file"] {
  display: none
}

label {
  padding: 1em 1em;
  background-color: #01e769;
  color: #191919;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  display: block;
  cursor: pointer;
  transition: .5s;
}

label:hover {
  filter: brightness(120%) saturate(120%);
}

#file-preview {
  display: inline-block;
  border-radius: 50%;
  height: 15em;
  width: 17em;
  object-fit: cover;
  border: 5px solid #01e769
}
</style>
