<template>
  <div class="modal-overlay-pass">
    <div class="modal-pass" @click.stop>
      <form @submit.prevent="submit">
        <div class="close" @click="$emit('close-modal')">
          <img class="close-img" src="../assets/close-icon.svg" alt="" />
        </div>
        <h6 class="card_title text-white changePass text-center">
          {{ $t("changePassword.alterar-senha") }}
        </h6>
        <div
          class="form-group flex flex-col mb-4"
          v-if="this.message != $t('changePassword.message')"
        >
          <span for="password" class="mb-2 font-bold">{{ $t("changePassword.senha-atual") }}</span>
          <input
            id="password"
            type="password"
            class="form-control"
            v-model="form.current"
            :placeholder="$t('changePassword.label')"
          />
          <FieldError :invalidFields="invalidFields" name="password" />
        </div>
        <div
          class="form-group flex flex-col mb-4"
          v-if="this.message != 'Senha alterada com sucesso!'"
        >
          <span for="password" class="mb-2 font-bold">{{ $t("changePassword.nova-senha") }}</span>
          <input
            id="password"
            type="password"
            class="form-control"
            v-model="form.new_password"
            :placeholder="$t('changePassword.label')"
          />
          <FieldError :invalidFields="invalidFields" name="password" />
          <div class="flex gap-1">
            <div
              class="pass-strength"
              :class="{ active: passwordStrength >= 1 }"
            ></div>
            <div
              class="pass-strength"
              :class="{ active: passwordStrength >= 2 }"
            ></div>
            <div
              class="pass-strength"
              :class="{ active: passwordStrength >= 3 }"
            ></div>
            <div
              class="pass-strength"
              :class="{ active: passwordStrength >= 4 }"
            ></div>
          </div>
        </div>

        <div
          class="form-group flex flex-col mb-4"
          v-if="message != $t('changePassword.message')"
        >
          <span for="password" class="mb-2 font-bold">{{ $t("changePassword.repita-senha") }}</span>
          <input
            id="password"
            type="password"
            class="form-control"
            v-model="form.new_password_confirmation"
            :placeholder="$t('changePassword.label')"
          />
          <FieldError
            :invalidFields="invalidFields"
            name="password_confirmation"
          />
        </div>
        <div v-if="message">
          <p
            :class="
              message == $t('changePassword.message')
                ? 'text-green mb-3'
                : 'text-red mb-2'
            "
          >
            {{ message }}
          </p>
          <router-link
            v-if="message == $t('changePassword.message')"
            to="/login"
            class="btn btn-grad"
            >{{ $t('changePassword.voltar') }}</router-link
          >
        </div>
        <div v-if="message != $t('changePassword.message')">
          <button type="submit" class="btn block w-full btn-outline-primary">
            {{ $t('changePassword.alterar-senha') }}
          </button>
        </div>

      </form>
      <div class="toast" :class="{ active: toastActive, message: message }">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import FieldError from "@/components/FieldError";
import { mapGetters } from "vuex";

export default {
  components: { FieldError },
  props: ["token"],
  data() {
    return {
      invalidFields: [],
      message: "",
      form: {
        current: "",
        new_password: "",
        new_password_confirmation: "",
      },
      toastActive: false,
    };
  },
  computed: {
    passwordStrength() {
      let strength = 0;
      if (this.form.new_password.length > 6) {
        strength++;
      }
      if (/[A-Z]/.test(this.form.new_password)) {
        strength++;
      }
      if (/[a-z]/.test(this.form.new_password)) {
        strength++;
      }
      if (/[^0-9a-zA-Z]/.test(this.form.new_password)) {
        strength++;
      }

      return strength;
    },

    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    user() {
      this.syncUser();
    },
  },
  created() {
    if (!this.user) {
      this.$store.dispatch("fetchUser");
    }

    this.syncUser();
  },
  methods: {
    async submit() {
      this.message = false;
      let form = this.form;

      if (this.token) {
        form = { ...this.form, token: this.token };
      }
      const response = await api.patch("user/update-password", form);
      if (!response.errors) {
        this.message = this.$t('changePassword.message');
        this.toastActive = true;
      } else {
        this.message = response.message;
      }

      setTimeout(() => {
        this.toastActive = false;
      }, 3000);
    },
    formIsValid() {
      let valid = true;
      this.invalidFields = [];

      Object.keys(this.form).map((field) => {
        if (this.form[field] === "") {
          this.invalidFields = [
            ...this.invalidFields,
            `${field}:${this.$t('changePassword.campo-vazio')}`,
          ];
          valid = false;
        }
      });

      return valid;
    },
    syncUser() {
      if (this.user) {
        this.form.current = this.user.password;
      }
      // console.log(this.form.current);
      // console.log(this.user.password);
    },
  },
};
</script>

<style scoped>

.changePass {
  margin-bottom: 10px;
}


.modal-overlay-pass {
  position: fixed; 
  z-index: 10; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);

}

.modal-pass {
  background-color: var(--c-primaryDark);
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .modal-pass {
    position: fixed;
    padding-top: 2.2em;
    margin: auto 15px 15px;
    width: 90%;
    z-index: 99999;
    }
}

.close {
  /* margin: 10% 0 0 16px; */
  cursor: pointer;
  float: right;
  top: 45px;
  right: 330px;
}

.close-img {
  width: 25px;
}
.pass-strength {
  margin-top: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  flex: 1;
}

.pass-strength.active:first-child {
  background: #f50000;
}
.pass-strength.active:nth-child(2) {
  background: #f59e00;
}
.pass-strength.active:nth-child(3) {
  background: #cff500;
}
.pass-strength.active:nth-child(4) {
  background: rgb(0, 255, 0);
}
</style>
